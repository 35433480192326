import { Link } from "gatsby"
import React, { useState } from "react"
import CheckCircle from "../../../assets/heroicons-0.4.1/optimized/solid/check-circle.svg"
import addToMailchimp from "gatsby-plugin-mailchimp"

const Newsletter = ({ noLabel, text, id, onLight }) => {
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = () => {
    addToMailchimp(email).then(data => {
      setSubmitted(true)
    })
  }

  if (submitted) {
    return (
      <div className="flex items-center">
        <CheckCircle className="h-8 w-8 md:h-12 md:w-12 mr-2 md:mr-4"/>
        <h2 className="text-2xl md:text-4xl font-bold">You're all signed up!</h2>
      </div>
    )
  }

  return (
    <form
      action="#"
      className="mt-2"
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      {!noLabel && (
        <label htmlFor={"email" + id ? id : ""} className="font-bold ">
          Sign up to find out more.
        </label>
      )}
      <div className="grid grid-cols-10 gap-4 my-3 text-gray-800">
        <input
          id={"email" + id ? id : ""}
          aria-label="email"
          type="email"
          placeholder="Enter your email"
          className={`input col-span-10 sm:col-span-7 h-12 px-2 rounded ${onLight?"border-brand-blue border-2":""}`}
          required
          style={{ marginTop: 0 }}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <div className="col-span-10 sm:col-span-2 text-center">
          <button className="w-full btn-secondary " style={{ maxWidth: 1000 }}>
            Sign Up
          </button>
        </div>
      </div>
      <p className={`${text ? text : "text-gray-800"}`}>
        We care about the protection of your data. Read our{" "}
        <Link to="/privacy" className="underline  font-medium">
          Privacy Policy
        </Link>
        .
      </p>
    </form>
  )
}

export default Newsletter
